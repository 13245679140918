<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="order-page-main-sec invoice-page-main-sec agency-design-grid-right"
    >
      <div class="desktop-respovsive-main-sec">
        <div class="invoice-history-content">
          <h2 class="text-heading">Invoice and Billing History</h2>
          <div class="text-1">
            Review your billing history and manage your invoices.
          </div>
        </div>

        <div class="editor-dashboard-sec-2">
          <div class="order-list-tabs">
            <div class="order-input-field">
              <!-- <div class="search-box">
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Search</label>

                    <div class="form-group">
                      <button class="search-button">
                        <i class="fas fa-search"></i>
                      </button>
                      <input
                        type="text"
                        class="form-control"
                        id="formGroupExampleInput"
                        v-model="search.all_invoice"
                        placeholder="Search all invoices"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="date-picker-box">
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Filter by date</label>
                    <div class="input-daterange input-group" id="m_datepicker">
                      <DatePickerComponent
                        :minDate="minDate"
                        :maxDate="maxDate"
                        :options="options"
                        :position="position"
                        :refVal="'datepickerMenu'"
                        :orderDate="filter.order_date"
                        @getSearchData="getSearchData"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="download-icon-list">
                <div @click="downloadFile()" class="top_icons">
                  <i
                    class="fas fa-file-export"
                    v-b-tooltip.hover.top="'Export CSV'"
                  ></i>
                </div>
                <div class="per-page-right-box">
                  <div class="select-item">
                    <select
                      @change="changePagination()"
                      v-model="filter.per_page"
                    >
                      <option>50</option>
                      <option>100</option>
                      <option>200</option>
                      <option>500</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <form>
              <table class="addon-table">
                <tr>
                  <th>Transaction For</th>
                  <th>Payment Method</th>
                  <th>Amount</th>
                  <th>Date Created</th>
                  <th>Action</th>
                </tr>
                <tr v-for="billing in billingDetail" :key="billing.id">
                  <td>{{ billing.type }}</td>
                  <td>{{ billing.payment_method }}</td>
                  <td>{{ numberFormat(billing.amount, "currency") }}</td>
                  <td>{{ billing.created_at }}</td>
                  <td>
                    <div class="btn-box" v-if="billing.type == 'Order'">
                      <router-link
                        :disabled="billing.type != 'Order' ? true : false"
                        :to="
                          billing.type == 'Order'
                            ? `/invoice/${billing.id}`
                            : ''
                        "
                        v-b-tooltip.hover.top="'View'"
                        ><i class="fas fa-eye"></i
                      ></router-link>
                    </div>
                  </td>
                </tr>
              </table>
            </form>
            <div class="total-records-list">
              <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination-list">
        <!-- <ul>
          <li>
            <a href="#"><i class="fas fa-chevron-left"></i> Prev</a>
          </li>
          <li><a href="#" class="active">1</a></li>
          <li><a href="#">2</a></li>
          <li><a href="#">3</a></li>
          <li><a href="#">4</a></li>
          <li><a href="#">5</a></li>
          <li>
            <a href="#">Next <i class="fas fa-chevron-right"></i></a>
          </li>
        </ul> -->
        <paginate
          :click-handler="getBillingDetail"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :page-count="filter.last_page"
          :page-range="3"
          :margin-pages="2"
          :container-class="'className'"
          :records="filter.length"
          v-model="filter.page"
          :per-page="filter.per_page"
        >
        </paginate>
      </div>
    </section>
  </div>
</template>

<style></style>

<script>
import DatePickerComponent from "@/components/DatePickerComponent.vue";
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { numberFormat } from "@/prod.js";
import Paginate from "vuejs-paginate-next";
export default {
  components: {
    HeaderView,
    DatePickerComponent,
    Paginate,
    LoaderComponent,
  },

  mounted() {
    this.getBillingDetail();
  },
  data() {
    return {
      numberFormat,
      loading: false,
      form: {
        name: "",
        email: "",
        phone: "",
        company: "",
        password: "",
      },
      showPassword: false,
      filter: {
        s: "",
        e: "",
        order_date: "",
        columnTo: "id",
        orderBy: "desc",
        per_page: 50,
        page: 1,
        last_page: 1,
        length: "",
      },
      minDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      maxDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      paginationData: {},

      billingDetail: [],

      search: {
        s: "",
        e: "",
        all_invoice: "",
      },
      date: new Date(),
      dateFormat: "MM-dd-yyyy",
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      totalRecords: 0,
      rows: [],
      selectedRange: "",
      position: "left",
    };
  },
  methods: {
    getSearchData(value) {
      this.filter.order_date = value;
      this.getBillingDetailSearch();
    },
    async getBillingDetail() {
      this.loading = true;
      this.filter.s = this.filter.order_date ? this.filter.order_date[0] : "";
      this.filter.e = this.filter.order_date ? this.filter.order_date[1] : "";
      try {
        let res = await this.$store.dispatch(
          "Billing/getBillingDataAction",
          this.filter
        );
        this.billingDetail = res.data;
        this.paginationData = res;
        this.totalRecords = res.meta.total;
        this.rows = res.data;
        this.filter.page = res.meta.current_page;
        this.filter.per_page = res.meta.per_page;
        this.filter.last_page = res.meta.last_page;
        this.filter.length = res.meta.total;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    getBillingDetailSearch() {
      this.filter.page = 1;
      this.getBillingDetail();
    },

    downloadFile() {
      // Convert Object to JSON if not
      var jsonObject = this.billingDetail;
      var array =
        typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject;

      var Head = [
        [
          '"Transaction For"',
          '"Payment Method"',
          '"Amount"',
          '"Date Created"',
        ],
      ];

      var row = [];
      for (var i = 0; i < array.length; i++) {
        row.push({
          key1: '"' + array[i].type + '"',
          key2: '"' + array[i].payment_method + '"',
          key3: '"$' + array[i].amount + '"',
          key4: '"' + array[i].created_at + '"',
        });
      }

      for (var item = 0; item < row.length; ++item) {
        Head.push([
          row[item].key1,
          row[item].key2,
          row[item].key3,
          row[item].key4,
        ]);
      }

      var csvRows = [];
      for (var cell = 0; cell < Head.length; ++cell) {
        csvRows.push(Head[cell].join(","));
      }

      var csvString = csvRows.join("\n");
      let csvFile = new Blob([csvString], {
        type: "text/csv",
      });
      let downloadLink = document.createElement("a");
      downloadLink.download = "Billing.csv";
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },

    changePagination() {
      this.filter.page = 1;
      this.getBillingDetail();
    },
  },

  computed: {},
};
</script>
 