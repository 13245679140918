
<template>
  <div class="home">
  <section class="agency-signUp-main-sec">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="responsive-logo">
            <a href="#"><img src="@/assets/common/images/lbhq-logo.svg" /></a>
          </div>
          <a href="#" class="desktopLogo"
            ><img src="@/assets/common/images/lbhq-logo.svg"
          /></a>
          <div class="form-box signIn-form-box">
            <div class="text-heading">Set New Password</div>
            <div v-if="status" class="alert alert-success">
              {{ status }} 
            </div>
            <form>
              <div class="form-group">
                <label for="exampleInputPassword1">Password</label>
                <input v-bind:type="[showPassword ? 'text' : 'password']" v-model="data.password" class="form-control"
                  id="exampleInputPassword1" />
                <span class="eye-icon" @click="showPassword = !showPassword">
                  <i class="fa" :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"></i>
                </span>
                <span v-if="errors.password" class="invalid-feedback" style="display: block" role="alert">
                  <strong>{{ errors.password[0] }}</strong>
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword2">Confirm Password</label>
                <input v-bind:type="[confirmShowPassword ? 'text' : 'password']" v-model="data.password_confirmation"
                  class="form-control" id="exampleInputPassword2" />
                <span class="eye-icon" @click="confirmShowPassword = !confirmShowPassword">
                  <i class="fa" :class="[confirmShowPassword ? 'fa-eye-slash' : 'fa-eye']"></i>
                </span>
                <span v-if="errors.password_confirmation" class="invalid-feedback" role="alert" style="display: block">
                  <strong>{{ errors.password_confirmation[0] }}</strong>
                </span>
              </div>
              <button type="button" class="btn-1" @click="setAssociatePassword">
                {{ button.text }}
                <b-spinner small v-show="loading"></b-spinner>
              </button>
            </form>
          </div>
        </div>

        <div class="col-md-6">
          <ContentBoxComponent></ContentBoxComponent>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>

<style>

</style>

<script>
import ContentBoxComponent from "@/components/ContentBoxComponent.vue";

export default {
  name: 'UserInvitationView',
  components: {
    ContentBoxComponent,
  },
  mounted() {
    if (this.$route.query.code) {
      this.data.code = this.$route.query.code;
      this.passwordFor = 'Associate';
    }
    if (this.$route.query.token) {
      this.agencyData.token = this.$route.query.token;
      this.passwordFor = 'Agency';
    }
  },
  props: ["token"],
  data() {
    return {
      loading: false,
      passwordFor: '',
      data: {
        code: '',
        password: "",
        password_confirmation: "",
      },
      agencyData: {
        token: '',
        password: "",
        password_confirmation: "",
      },
      showPassword: false,
      confirmShowPassword: false,
      errors: {
        email: [],
        password: [],
      },
      button: {
        text: "Confirm",
      },
      seen: true,
      status: false,
    };
  },
  methods: {
    async setAssociatePassword() {
      this.loading = true;
      let res;
      try {
        if(this.passwordFor == 'Agency'){
          this.agencyData.password = this.data.password;
          this.agencyData.password_confirmation = this.data.password_confirmation;
          res = await this.$store.dispatch("Auth/setAgencyPasswordAction", this.agencyData, {
            root: true
          });
        }else{
          res = await this.$store.dispatch("Auth/setPasswordAction", this.data, {
            root: true
          });
        }
          if (res.success) {
            this.loading = false;
            this.$router.push("/dashboard");
          }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;

      }
    },

    async setAgencyPassword() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch("Auth/setAgencyPasswordAction", this.agencyData, {
          root: true
        });
        if (res.success) {
          this.loading = false;
          this.$router.push("/dashboard");
        }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;

      }
    },

  },
};
</script>
