<template>
  <div class="detail-list-box">
    <table>
      <tr>
        <td colspan="3">
          <div class="text-1">
            Placement URL<span class="bText">{{
              this.links.placement_url
            }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="text-1">
            Category
            <span>{{ this.links.category }}</span>
          </div>
        </td>

        <td>
          <div class="text-1">
            DA
            <span>{{ this.links.product }}</span>
          </div>
        </td>

        <!-- <td>
          <div class="text-1">
            DR
            <span>{{ this.links.exact_dr }}</span>
          </div> 
        </td> -->

        <td>
          <div class="text-1">
            Spam Score <span>{{ this.links.spam_score }}</span>
          </div>
        </td>
        <td>
          <div class="text-1">
            Traffic <span>{{ Math.floor(this.links.traffic).toLocaleString() }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="text-1">
            Secondary Category
            <span>{{ this.links.secondary_category }}</span>
          </div>
        </td>
        <td>
          <div class="text-1">
            Type
            <span v-if="this.links.type != 'Large Publications'">{{ this.links.type }} ({{ this.links.mo_detail }})</span>
            <span v-else>{{ this.links.type }}</span>
          </div>
        </td>
        <td>
          <div class="text-1">
            Do Follow
            <span>{{ this.links.do_follow }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <div class="text-1" style="width: inherit;">
            Notes
            <span>{{ this.links.notes }}</span>
          </div>
        </td>
      </tr>
      <!-- <tr>
        <td colspan="3">
          <div class="text-1">
            Sub Category <span>{{ this.links.secondary_category }}</span>
          </div>
        </td>
      </tr> -->
      <template v-if="this.links.status == 'Ordered'">
        <tr>
          <td colspan="3">
            <div class="text-1">
              Ordered For
            </div>
          </td>
        </tr>
        <tr v-for="link in this.links.detail" :key="link.link_id">
          <td colspan="3">
            <div class="text-1">
              <span>{{ link.destination_url }} 
                <span class="destination-count" v-if="link.destination_count > 1"> (x{{ link.destination_count }})</span>
              </span>
            </div>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
export default {
  props: ['opportunityDetail'],
  watch: {
    opportunityDetail() {
        this.links = this.opportunityDetail;
      },
    },
  mounted() {
  
  },
  data() {
    return {
      links: [],
    };
  },
  methods: {

  },
  computed: {
  },
};
</script>
