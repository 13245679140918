<template>
  <section class="agency-signUp-main-sec">
    <div class="container-fluid">
      <div class="row align-item-cemter">
        <div class="col-md-6">
          <div class="responsive-logo">
            <router-link class="desktopLogo" :to="'login'" ><img src="@/assets/common/images/lbhq-logo.svg" />
            </router-link>
          </div>
          
          <router-link class="desktopLogo" :to="'login'" >
            <img src="@/assets/common/images/lbhq-logo.svg" />
          </router-link>
          <div class="top-btn-box">
            <router-link :to="queryParam ? `login${queryParamValue}` : 'login'"
              >Sign in</router-link
            >
            <router-link
              :to="queryParam ? `forget-password${queryParamValue}` : 'forget-password'"
              >Forgot Password</router-link
            >
          </div>
          <div class="form-box reset-form-box">
            <!-- <div class="text-heading">Forgotten Password</div> -->
            <div v-if="status" class="alert alert-success">
              {{ status }}
            </div>
            <div class="account-text-1">
              Enter your email to reset your<br />
              password:
            </div>
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="email"
                  id="exampleInputEmail1"
                />
                <span
                  v-if="errors.email"
                  class="invalid-feedback"
                  style="display: block"
                  role="alert"
                >
                  <strong>{{ errors.email[0] }}</strong>
                </span>
              </div>

              <button
                type="button"
                class="btn-1"
                @click="request"
                :class="{ disabled: !seen }"
              >
                {{ button.text }}
                <b-spinner small v-show="isLoading"></b-spinner>
              </button>
              <!-- <div class="sign-in-text">
                Back to sign in?
                <router-link to="login">Sign in</router-link>
              </div> -->
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <ContentBoxComponent></ContentBoxComponent>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContentBoxComponent from "@/components/ContentBoxComponent.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "ForgetView",
  components: {
    ContentBoxComponent,
  },
  mounted() {
    window.document.body.style.paddingLeft = '0px';
  },
  data() {
    return {
      email: "",
      errors: [],
      isLoading: false,
      button: {
        text: "Request",
      },
      seen: true,
      showPassword: false,
      status: false,
    };
  },
  methods: {
    async request() {
      this.isLoading = true;
      this.errors = [];
      try {
        let res = await this.$store.dispatch("Auth/forgetPasswordAction", {
          email: this.email,
        });

        if (res.success) {
          this.isLoading = false;
          toast.success(res.message, { timeout: 3000 });
          // this.status = res.message;
        }
      } catch (error) {
        this.isLoading = false;
        this.errors = error.response.data.errors;
      }
    },
  },
};
</script>
