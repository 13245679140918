
<template>
  <section class="agency-signUp-main-sec">
    <div class="container-fluid">
      <div class="row align-item-cemter">
        <div class="col-md-6">
          <div class="responsive-logo">
            <router-link class="desktopLogo" :to="'login'" ><img src="@/assets/common/images/lbhq-logo.svg" />
            </router-link>
          </div>
          
          <router-link class="desktopLogo" :to="'login'" >
            <img src="@/assets/common/images/lbhq-logo.svg" />
          </router-link>
          <div class="form-box signIn-form-box">
            <div class="text-heading">Set New Password</div>
            <div v-if="status" class="alert alert-success">
              {{ status }}
            </div>
            <form> 
              <div class="form-group">
                <label for="exampleInputPassword1">Password</label>
                <input
                  v-bind:type="[showPassword ? 'text' : 'password']"
                  @keyup.enter="handleEnterKey"
                  v-model="data.password"
                  class="form-control"
                  id="exampleInputPassword1"
                />
                <span class="eye-icon" @click="showPassword = !showPassword">
                  <i
                    class="fa"
                    :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
                  ></i>
                </span>
                <span
                  v-if="errors.password"
                  class="invalid-feedback"
                  style="display: block"
                  role="alert"
                >
                  <strong>{{ errors.password[0] }}</strong>
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword2">Confirm Password</label>
                <input
                  v-bind:type="[confirmShowPassword ? 'text' : 'password']"
                  @keyup.enter="handleEnterKey"
                  v-model="data.password_confirmation"
                  class="form-control"
                  id="exampleInputPassword2"
                />
                <span
                  class="eye-icon"
                  @click="confirmShowPassword = !confirmShowPassword"
                >
                  <i
                    class="fa"
                    :class="[confirmShowPassword ? 'fa-eye-slash' : 'fa-eye']"
                  ></i>
                </span>
                <span
                  v-if="errors.password_confirmation"
                  class="invalid-feedback"
                  role="alert"
                  style="display: block"
                >
                  <strong>{{ errors.password_confirmation[0] }}</strong>
                </span>
              </div>
              <button
                type="button"
                class="btn-1"
                @click="reset"
                :class="{ disabled: !seen }"
              >
                {{ button.text }}
                <b-spinner small v-show="isLoading"></b-spinner>
              </button>
              
              <div class="sign-in-text">
                Back to sign in?
                <router-link to="login">Sign in</router-link>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-6">
          <ContentBoxComponent></ContentBoxComponent>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
</style>

<script>

import ContentBoxComponent from "@/components/ContentBoxComponent.vue";
export default {
name: 'ResetView',
  components: {
      ContentBoxComponent
    },
  mounted() {
    if(this.$route.query.token){
      this.data.reset_password_code = this.$route.query.token
      }

      window.document.body.style.paddingLeft = '0px';
  },
  props: ["token"],

  data() {
    return {
      data: {
      reset_password_code : '',
      password: "",
      password_confirmation: "",
      },
      showPassword: false,
      confirmShowPassword: false,
      isLoading: false,
      errors: {
        email: [],
        password: [],
      },
      button: {
        text: "Reset",
      },
      seen: true,
      status: false,
    };
  },
    methods: {
      handleEnterKey() {
      this.reset();
    },
    async reset() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("Auth/resetPasswordAction", this.data, {
         root:true
        });
        if(res.success){
          this.isLoading = false;
          this.status = res.message;
          this.$router.push("/dashboard");
        }
      } catch (error) {
        this.isLoading = false;
        this.errors = error.response.data.errors; 
        
      }
    },

    },
};
</script>
