<template>
  <div
    class="editor-dashboard-sec-2 approval-list-sec draft-order-sec live-link-list"
  >
  <div class="missedOpportunity-list-sec">
    <div class="table-responsive">
      <table class="addon-table">
        <tr>
          <th>Placement URL</th>
          <th>DA</th>
          <!-- <th>DR</th> -->
          <th>Category</th>
          <th>Sub Category</th>
          <th>Spam Score</th>
          <th>Link Price</th>
        </tr>
        <tr
          v-for="missedOpportunity in missed_opportunities"
          :key="missedOpportunity.id"
        >
        <td class="missedOpportunity-text" v-b-tooltip.hover.top="missedOpportunity.placement_url">
            <a @click="redirectURL(missedOpportunity.placement_url)"
              ><span>{{ missedOpportunity.placement_url }}</span>
              <i
                v-if="
                  missedOpportunity.placement_url !== true
                    ? missedOpportunity.placement_url
                    : missedOpportunity.placement_url
                "
                class="fas fa-external-link-alt"
              ></i
            ></a>
          </td>
          <!-- <td>{{ missedOpportunity.exact_da }}</td>
          <td>{{ missedOpportunity.exact_dr }}</td> -->
          <td>{{ missedOpportunity.product }}</td>
          <td>{{ missedOpportunity.category }}</td>
          <td>{{ missedOpportunity.secondary_category }}</td>
          <td>{{ missedOpportunity.spam_score }}</td>
          <!-- <td>{{ numberFormat(missedOpportunity.total_cost, "currency") }}</td> -->
          <td>
            <template v-if="missedOpportunity.showPriceButton">
              <div v-if="missedOpportunity.request_price">Price <br> Requested</div>
              <div class="get-price-btn" v-else>
                <a href="javascript:;" class="btn-1" @click="getPrice(missedOpportunity.id)">Get Price</a>
              </div>
            </template>
            <template v-else>
              {{
                numberFormat(missedOpportunity.total_cost, "currency")
              }}
            </template>
          </td>
        </tr>
      </table>
    </div>
  </div>
  </div>
</template>

<script>
import { numberFormat } from "@/prod.js";
export default {
  props: ["missed_opportunities"],
  components: {},
  mounted() {},
  data() {
    return {
      numberFormat,
      loading: false,
    };
  },
  methods: {
    getPrice(value) {
      this.$emit('getPrice', value)
    },
    redirectURL(url) {
      const pattern = /^(http:\/\/|https:\/\/)/i;
      const hasHttpOrHttps = pattern.test(url);

      let finalUrl = url;
      if (!hasHttpOrHttps) {
        finalUrl = "https://" + url;
      }

      const newTab = window.open(finalUrl, "_blank");
      if (newTab) {
        newTab.focus();
      }
    },
  },
};
</script>
