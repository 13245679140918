<template>
              <div class="signUp-content-box signIn-content-box sticky_content">
            <!-- <a href="#" class="desktopLogo"><img src="@/assets/common/images/lbhq-logo.svg" /></a> -->
             <div class="right-content ">
            <div class="text-heading">
              Your Reliable <br />
              Link Building Buddy!
            </div>
            <div class="text-1">Achieve the Best Link Building Results</div>
            <ul>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b>Everlasting Links:</b> 
                Your links last a lifetime.
              </li>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b>High-Quality Links:</b> 
                Authentic outreach with top-notch content.
              </li>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b> White-Label Solutions:</b>  
                A 100+ Agencies Network.
              </li>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b>Manual Outreaching:</b>  
                The customizable method preferred by the experts.
              </li>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b>No PBNs:</b>  Legitimate Link Building Done Right.
              </li>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b>100% Refund:</b>  
                14-day hassle-free policy.
              </li>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b>Support:</b> Call, chat, or ping, anytime.
              </li>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b>Expertise:</b> Seamless processes with dedicated teams.
              </li>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b>Dashboard:</b> 
                Easy ordering, tracking, status updates, & much more.
              </li>
              <li>
                <!-- <i class="fas fa-check"></i>  -->
                <b>No Minimum:</b> 
                Order as many as you need.
              </li>
            </ul>
            <!-- <div class="link-image">
              <img src="@/assets/common/images/linkicon.png" />
            </div> -->
          </div>
          </div>
</template>

<script>
import jQuery from "jquery";
export default {
   components: {
  },
  mounted() {
    var div_top = jQuery('.sticky_content').offset().top;

jQuery(window).scroll(function() {
    var window_top = jQuery(window).scrollTop() - 0;
    if (window_top > div_top) {
        if (!jQuery('.sticky_content').is('.sticky')) {
            jQuery('.sticky_content').addClass('sticky');
        }
    } else {
        jQuery('.sticky_content').removeClass('sticky');
    }
});
  },

};
</script>
