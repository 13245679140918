<template>
  <div class="home">
    <LoaderComponent v-show="loading" :loaderText="loaderText"/>
    <HeaderView />
    <section class="order-page-main-sec track-order-main-sec order-detail-main-sec agency-design-grid-right">
      <div class="desktop-respovsive-main-sec">
        <div class="invoice-history-content">
          <h2 class="text-heading">My Orders</h2>
          <div class="text-1">Track all your orders and links</div>
        </div>

        <div class="editor-dashboard-sec-2">
          <div class="order-list-tabs">
            <ul>
              <li>
                <router-link to="/order"><i class="fas fa-arrow-alt-circle-left"></i></router-link>
              </li>
              <!-- <li v-for="status in statuses" :key="status.id">
                <div
                  class="text-1"
                  v-on:click="activeStatus(status.id, status.value)"
                  :v-model="linkFilter.status"
                >
                  {{ status.status }}<b v-b-tooltip.hover.top="status.value">{{ status.value }}</b>
                </div>
              </li> -->

              <li>
                <div class="text-1" >
                  Order Date <b>{{ status.date }}</b>
                </div>
              </li>
              <li>
                <div class="text-1">
                  Order ID <b>{{ status.id }}</b>
                </div>
              </li>
              <li>
                <div class="text-1">
                  Order Title <b v-b-tooltip.hover.top="status.order_title">{{ status.order_title }}</b>
                </div>
              </li>
              <li>
                <div class="text-1">
                  Total Links <b>{{ status.total_links }}</b>
                </div>
              </li>
              <li v-if="!status.missed_opportunity">
                <div class="text-1">
                  Total Add-Ons <b >{{ status.total_addons }}</b>
                </div>
              </li>
              <li>
                <div class="text-1">
                  Order Amount <b>{{ status.total }}</b>
                </div>
              </li>
              <li>
                <a :href="`/invoice/${invoice_id}`" target="_blank" class="btn-1 view-invoice-btn">
                  View Invoice
                </a>
                <!-- <i class="fas fa-file-invoice-dollar"></i> -->
              </li>
            </ul>
            <div class="order-input-field">
              <div class="search-box">
                <div class="approvedBy-text" v-if="approvedBy">
                  Order Approved By: {{ approvedBy }}
                </div>
              </div>
              <div class="order-filter-box">
                <div class="filter-btn" @click="openSidebar">
                  <i class="fas fa-filter"></i> Filters
                </div>
                <div class="download-icon-list">
                  <div @click="downloadLinkFile()" class="top_icons">
                    <i class="fas fa-file-export" v-b-tooltip.hover.top="'Export CSV'"></i>
                  </div>
                  <div class="per-page-right-box">
                    <div class="select-item">
                      <select @change="changePagination()" v-model="linkFilter.per_page">
                        <option>50</option>
                        <option>100</option>
                        <option>200</option>
                        <option>500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="filter-sideabar-box" :class="{ opensideBar: isSidebarOpen }">
                  <div class="filter-form-box">
                    <div class="top-bar">
                      <div class="text-heading">Filter</div>
                      <div class="icon-box">
                        <i class="fas fa-times" @click="closeSidebar"></i>
                      </div>
                    </div>

                    <b-form @submit.stop.prevent>
                      <!-- <b-col md="12">
                                                <label for="text-date">Order Date</label>
                                                <Datepicker id="example-datepicker" v-model="linkFilter.order_date"
                                                    class="mb-2" placeholder="mm-dd-yyyy - mm-dd-yyyy" :format="dateFormat" :config="options" range
                                                    :enable-time-picker="false"></Datepicker>
                                            </b-col> -->
                      <b-col md="12">
                        <label for="text-link">Link ID</label>
                        <b-form-input id="input-2" v-model="linkFilter.link_id" placeholder=""
                          required></b-form-input>
                      </b-col>
                      <b-col md="12">
                        <label for="text-link">Live Link</label>
                        <b-form-input id="input-2" v-model="linkFilter.live_link" placeholder=""
                          required></b-form-input>
                      </b-col>
                      <b-col md="12">
                        <label for="text-status">Status</label>
                          <VueMultiselect
                            id="input-3"
                            placeholder="Select Status"
                            v-model="linkFilter.status"
                            :options="linkStatuses"
                            :close-on-select="false" 
                            :clear-on-select="false" 
                            label="name" 
                            track-by="id"
                            :multiple="true"
                          >
                          <template #selection="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                        </VueMultiselect>
                      </b-col>

                      <b-col md="12">
                        <label for="text-domain">Destination URL</label>
                        <b-form-input id="input-2" v-model="linkFilter.destination_link" placeholder=""
                          required></b-form-input>
                      </b-col>

                      <!-- <b-col md="12">
                        <label for="text-primary">Primary Category</label>
                        <b-form-select
                          id="input-3"
                          v-model="linkFilter.primary_category"
                          :options="primaryCategoryList"
                          required
                        ></b-form-select>
                      </b-col> -->
                      <b-col md="12">
                        <label for="text-anchor">Anchor Text</label>
                        <b-form-input id="input-2" v-model="linkFilter.anchor_text" placeholder=""
                          required></b-form-input>
                      </b-col>
                      <!-- <b-col md="12">
                        <label for="text-status">Order By</label>
                        <b-form-select
                          id="input-3"
                          v-model="linkFilter.agency"
                          :options="agencies"
                          required
                        ></b-form-select>
                      </b-col> -->
                      <b-col md="12">
                        <div class="btn-box-list">
                          <a href="javascript:;" class="btn-1" @click="getLinks()">Search
                          </a>
                          <a href="javascript:;" class="btn-1 btn-2" @click="resetFilter()">Reset
                          </a>
                        </div>
                      </b-col>
                    </b-form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="order-detail-box">
            <div class="detail-sideabar-box" :class="{ opensideBar: orderSidebarOpen }">
              <LinksDetail :activeLink="activeLink" :reasons="reasons" :contentTabData="contentTabData"
                @closeOrderSidebar="closeOrderSidebar" :showOnlyDetail="false"></LinksDetail>
            </div>
          </div>
          <div class="table-responsive">
            <LinksDetailTable :links="links" :hideOrderId="hideOrderId" @openOrderSidebar="openOrderSidebar">
            </LinksDetailTable>
            <div class="total-records-list">
              <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-list">
        <paginate 
          :click-handler="getLinks" 
          :prev-text="'Prev'" 
          :next-text="'Next'" 
          :page-count="linkFilter.last_page"
          :page-range="3" 
          :margin-pages="2" 
          :container-class="'className'"
          :records="linkFilter.length" 
          v-model="linkFilter.page" 
          :per-page="linkFilter.per_page">
        </paginate>
      </div>
    </section>
    <!-- :next-text="linkFilter.next_page_url ==  null ? '' : 'Next'" -->
  </div>
</template>

<style></style>

<script>
// import Datepicker from '@vuepic/vue-datepicker';
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LinksDetailTable from "@/components/orderLinksComponents/OrderDetailComponent.vue";
import LinksDetail from "@/components/orderLinksComponents/LinkDetailComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { numberFormat } from "@/prod.js";
import Paginate from "vuejs-paginate-next";
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  name: "OrderDetailView",
  components: {
    HeaderView,
    LinksDetailTable,
    // Datepicker,
    LinksDetail,
    LoaderComponent,
    Paginate,
    VueMultiselect
  },
  mounted() {
    this.getOrderId();
    // this.linkFilter.order_id = localStorage.getItem("order_id");
    // this.getLinks();
    // this.getOrderDetail();
    // this.LinksStatus();
    // this.agenciesUsers();
    // this.CategoryList();
  },
  data() {
    return {
      loading: true,
      showPassword: false,
      loaderText: '',
      activeLink: "",
      search: {
        all_invoice: "",
        order_status: "",
      },
      paginationDataLinks: {},
      contentTabData: [],
      linkFilter: {
        page: 1,
        per_page: 50,
        last_page: 1,
        // s: '',
        // e: '',
        // order_date: '',
        order_id: "",
        link_id: "",
        live_link: "",
        anchor_text: "",
        destination_link: "",
        primary_category: "",
        next_page_url: null,
        status: null,
        content: null,
        columnTo: "id",
        orderBy: "desc",
        agency: null,
        linkDownload: 'no',
      },
      isSidebarOpen: false,
      isSidebarClose: true,
      orderSidebarOpen: false,
      orderSidebarClose: true,
      activeId: 1,
      statusValue: "orders",
      linksValue: "links",
      statusSelect: [
        { text: "Delivered", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      links: [],
      reasons: [],
      linkStatuses: [],
      primaryCategoryList: [{ text: "Select Category", value: null }],
      status:{
        date: "",
        id: "",
        order_title: "",
        total_links: "",
        total_addons: "",
        total: 0,
        missed_opportunity: false,
      },
      agencies: [{ text: "Select Order By", value: null }],
      hideOrderId: true,
      approvedBy: "",
      rows: [],
      totalRecords: 0,
      invoice_id: "",
    };
  },
  methods: {
    getOrderId(){
      var url = window.location.pathname;
      var order_id = url.substring(url.lastIndexOf('/') + 1);
      if (order_id) {
        this.linkFilter.order_id = decodeURIComponent(order_id);
        this.getLinks();
        this.getOrderDetail();
        this.LinksStatus();
        this.agenciesUsers();
        this.CategoryList();
      }else{
        this.$router.push("/dashboard");
      }
    },
    getSelectedIdsOnClose(){
      const selectedIdsOnClose = this.linkFilter.status.map(item => item.id);
      const commaSeparatedIds = selectedIdsOnClose.join(',');
      this.linkFilter.status = commaSeparatedIds;
    },
    searchWithLinkStatus() {
      this.$nextTick(function () {
        this.getLinks();
      });
    },
    async getOrderDetail() {
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          "Product/getOrdersDataAction",
          this.linkFilter
        );
        // this.orders = res.orders;
        this.status.missed_opportunity = res.orders[0].missed_opportunity;
        this.status.date = res.orders[0].date;
        this.status.id = res.orders[0].id;
        this.status.order_title = res.orders[0].order_title;
        this.status.total_links = res.orders[0].total_links;
        this.status.total_addons = res.orders[0].total_addons;
        this.status.total = numberFormat(res.orders[0].total, "currency");
        this.invoice_id = res.orders[0].invoice_id;
        // this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async getLinks() {
      this.loading = true;
      // this.linkFilter.s = this.linkFilter.order_date ? this.linkFilter.order_date[0] : '';
      // this.linkFilter.e = this.linkFilter.order_date ? this.linkFilter.order_date[1] : '';
      this.linkFilter.linkDownload = 'no';
      try {
        let res = await this.$store.dispatch(
          "Link/getAllLinksDataAction",
          this.linkFilter
        );
        if(res.data.links.length > 0){
          this.links = res.data.links;
          if (res.data.estimatedArticleRequest) {
            this.contentTabData = res.data.estimatedArticleRequest;
          }
          this.rows = res.data.links;
          this.totalRecords = res.meta.total;
          this.reasons = res.reasons;
          this.approvedBy = this.links[0].approved_by;
          this.paginationDataLinks = res.data.links;
          this.linkFilter.page = res.meta.current_page;
          this.linkFilter.per_page = res.meta.per_page;
          this.linkFilter.last_page = res.meta.last_page;
          this.linkFilter.length = res.meta.total;
          this.linkFilter.next_page_url = res.meta.next_page_url;
        }else{
          this.$router.push("/dashboard");
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async LinksStatus() {
      try {
        let res = await this.$store.dispatch("Link/getAllLinkStatusDataAction");
        res.forEach((item) => {
          this.linkStatuses.push({ id: item.id, name: item.status });
        });
      } catch (e) {
        this.loading = false;
      }
    },
    async agenciesUsers() {
      try {
        let res = await this.$store.dispatch("Link/getAgenciesUsersDataAction");
        res.agencies.forEach((item) => {
          this.agencies.push({ text: item.name, value: item.id });
        });
      } catch (e) {
        this.loading = false;
      }
    },
    async CategoryList() {
      try {
        let res = await this.$store.dispatch("Link/getAllCategoryDataAction");
        res.forEach((item) => {
          this.primaryCategoryList.push({ text: item.name, value: item.id });
        });
      } catch (e) {
        this.loading = false;
      }
    },
    activeStatus: function (id, value) {
      this.activeId = id;
      this.statusValue = value;
      this.linksValue = value;
    },

    openSidebar() {
      setTimeout(() => (this.isSidebarOpen = true), 500);
      setTimeout(() => (this.isSidebarClose = false), 500);
    },

    closeSidebar() {
      setTimeout(() => (this.isSidebarOpen = false), 500);
    },

    openOrderSidebar(linksDetail) {
      this.activeLink = linksDetail;
      setTimeout(() => (this.orderSidebarOpen = true), 500);
      setTimeout(() => (this.orderSidebarClose = false), 500);
    },

    closeOrderSidebar() {
      setTimeout(() => (this.orderSidebarOpen = false), 500);
    },

    resetFilter() {
      // this.linkFilter.order_date = "";
      this.linkFilter.link_id = "";
      this.linkFilter.live_link = "";
      this.linkFilter.anchor_text = "";
      this.linkFilter.destination_link = "";
      this.linkFilter.primary_category = "";
      this.linkFilter.status = null;
      this.linkFilter.agency = null;
      this.getLinks();
    },

    async downloadLinkFile() {
      this.loaderText = 'Please wait while we are processing your request';
      this.loading = true;
      this.linkFilter.linkDownload = 'yes';
      try {
        const response = await this.$store.dispatch( 
          "Link/getAllLinksDataAction",
          this.linkFilter
        );
        let blob = new Blob([response], {
          type: "application/csv",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Links.csv';
        link.click();
        this.loaderText = '';
        this.loading = false;
      } catch (e) {
        this.loaderText = '';
        this.loading = false;
      }
    },

    changePagination() {
      this.linkFilter.page = 1;
      this.getLinks();
    },
    getSortedResults(column, order) {
      this.linkFilter.columnTo = column;
      this.linkFilter.orderBy = order;
      this.getLinks();
    },
  },

  computed: {},
};
</script>
